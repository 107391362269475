import {
	CheckIcon,
	CopyIcon,
	DeleteIcon,
	EditIcon,
	ViewIcon,
	ViewOffIcon,
} from "@chakra-ui/icons";
import {
	Button,
	HStack,
	IconButton,
	Text,
	useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { LanguageMode } from "../../models/language-modes";
import { CodeSnippet } from "../../models/models";
import { ToolTipButton } from "../components/tooltip-button";

interface ToolBarViewProps {
	isReview?: boolean;
	snippet: CodeSnippet;
	isReviewVisible: boolean;
	languageMode: string;
	isCreatingNewRevision: boolean;
	isPostingNewRevision: boolean;
	isMarkingAsReviewed?: boolean;
	onCopyCode: () => void;
	onAnnotate: () => void;
	onReviewVisibilityChange: () => void;
	onDeletePost: () => void;
	onNewRevision: () => void;
	onDiscardRevision: () => void;
	onRequestReviewOfRevision: () => void;
	onMarkAsReviewed?: () => void;
}

interface ToolbarIconButtonProps {
	isMobileView: boolean;
	onClick: () => void;
	text: string;
	icon: any;
	toolTip: string;
	isLoading?: boolean;
}

const ToolbarIconButton = (props: ToolbarIconButtonProps) => {
	if (props.isMobileView) {
		return (
			<IconButton
				size="sm"
				aria-label={props.text}
				onClick={() => {
					props.onClick();
				}}
			>
				{props.icon}
			</IconButton>
		);
	}
	return (
		<ToolTipButton
			toolTipLabel={props.toolTip}
			icon={props.icon}
			onClick={() => {
				props.onClick();
			}}
			isLoading={props.isLoading}
		>
			{props.text}
		</ToolTipButton>
	);
};

interface LanguageModeViewProps {
	isMobileView: boolean;
	languageMode: string;
}
const LanguageModeView = (props: LanguageModeViewProps) => {
	const displayName = LanguageMode.getModeDisplayName(props.languageMode);
	const text = props.isMobileView
		? `${displayName.substr(0, 2)}`
		: `${displayName}`;
	return <Text fontSize="sm">{text}</Text>;
};

export const ToolBarView = (props: ToolBarViewProps) => {
	const [isLargerThan1280] = useMediaQuery("(min-width: 400px)");

	const newRevisionToolButton = (
		<Button
			isLoading={props.isPostingNewRevision}
			size={isLargerThan1280 ? "md" : "sm"}
			loadingText="Requesting..."
			onClick={() => {
				props.onNewRevision();
			}}
			colorScheme="green"
			variant="outline"
		>
			New Revision
		</Button>
	);

	const discardAndRequestReviewView = (
		<>
			<ToolbarIconButton
				icon={null}
				text={"Discard Revision"}
				toolTip="Discard revision"
				isMobileView={false}
				onClick={() => props.onDiscardRevision()}
			/>
			<Button
				isLoading={props.isPostingNewRevision}
				size={isLargerThan1280 ? "sm" : "sm"}
				loadingText="Requesting..."
				onClick={() => {
					props.onRequestReviewOfRevision();
				}}
				colorScheme="green"
				variant="outline"
			>
				Request Review
			</Button>
			{/* <ToolbarIconButton
				icon={<CopyIcon />}
				text={"Request Review"}
				toolTip="Request Review"
				isMobileView={false}
				onClick={() => props.onRequestReviewOfRevision()}
			/> */}
		</>
	);

	let revisionButtonViews = null;
	if (isLargerThan1280 && props.snippet.isAuthor) {
		revisionButtonViews = props.isCreatingNewRevision
			? discardAndRequestReviewView
			: newRevisionToolButton;
	}
	return (
		<HStack spacing="10px">
			{/* <Spacer /> */}
			{isLargerThan1280 ? (
				<LanguageModeView
					isMobileView={!isLargerThan1280}
					languageMode={props.languageMode}
				/>
			) : null}

			<ToolbarIconButton
				icon={<CopyIcon />}
				text={"Copy"}
				toolTip="Copy Code Text"
				isMobileView={!isLargerThan1280}
				onClick={() => props.onCopyCode()}
			/>

			<ToolbarIconButton
				icon={<DeleteIcon />}
				text={"Delete"}
				toolTip="Delete snippet"
				isMobileView={!isLargerThan1280}
				onClick={() => props.onDeletePost()}
			/>

			<ToolbarIconButton
				icon={props.isReviewVisible ? <ViewIcon /> : <ViewOffIcon />}
				text={"Reviews"}
				toolTip="Reviews"
				isMobileView={!isLargerThan1280}
				onClick={() => props.onReviewVisibilityChange()}
			/>

			<ToolbarIconButton
				icon={<EditIcon />}
				text={"Annotate"}
				toolTip="Annotate code"
				isMobileView={!isLargerThan1280}
				onClick={() => props.onAnnotate()}
			/>

			{props.isReview ? (
				<ToolbarIconButton
					icon={<CheckIcon />}
					text={`Mark (${props.snippet.hasBeenReviewed ? "R" : "NR"})`}
					toolTip="Mark as reviewed"
					isMobileView={!isLargerThan1280}
					isLoading={props.isMarkingAsReviewed}
					onClick={() => {
						if (props.onMarkAsReviewed) {
							props.onMarkAsReviewed();
						}
					}}
				/>
			) : null}

			{revisionButtonViews}

			{/* <Spacer /> */}
		</HStack>
	);
};
