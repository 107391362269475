import {
	LinkBox,
	LinkOverlay,
	Box,
	Heading,
	Flex,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React from "react";
import { ThemeAppearance } from "../../appearance/appearance";
import { CodeSnippet } from "../../models/models";
import { CodeEditorView } from "../code-editor/code-editor-view";

interface CodeSnippetMiniViewProps {
	post: CodeSnippet;
	detaiLink: string;
}

export const CodeSnippetMiniView = ({
	post,
	detaiLink,
}: CodeSnippetMiniViewProps) => {
	return (
		<LinkBox
			as="article"
			background={ThemeAppearance.cardBackground}
			padding="2em"
			rounded={"10px"}
			shadow="md"
			borderWidth="1px"
		>
			<LinkOverlay href={`${detaiLink}`}>
				<Box>
					<Heading fontSize="l" marginBottom="20px">
						{post.title}
					</Heading>
					<Box height="100px">
						<CodeEditorView
							readOnly={true}
							interactionDisabled
							value={post.codeText}
							languageMode={post.languageMode}
							onCursorPositionChange={(value) => {}}
							onTextChange={() => {}}
							name={`${post.snippetId}-div`}
							width="100%"
							height="100%"
						/>
						{/* <Box position="relative" top="-100px" height="100px"></Box> */}
					</Box>

					{/* </Box> */}
					<Flex direction="row" justifyContent="center" marginTop="10px">
						<Text fontSize="xs" fontStyle="italic">
							{`${post.dateTime.toDateString()} (${post.dateTime.getHours()}:${post.dateTime.getMinutes()})`}
						</Text>
						<Spacer />
						<Text fontSize="xs">revision {post.revision}</Text>
					</Flex>
				</Box>
			</LinkOverlay>
		</LinkBox>
	);
};

// interface CodeSnippetForReviewMiniViewProps {
// 	post: CodeSnippetForReview;
// 	detaiLink: string;
// }

// export const CodeSnippetForReviewMiniView = ({
// 	post,
// 	detaiLink,
// }: CodeSnippetForReviewMiniViewProps) => {
// 	return (
// 		<LinkBox
// 			as="article"
// 			background={ThemeAppearance.cardBackground}
// 			padding="2em"
// 			rounded={"10px"}
// 			shadow="md"
// 			borderWidth="1px"
// 		>
// 			<LinkOverlay href={`${detaiLink}`}>
// 				<Box>
// 					<Heading fontSize="l" marginBottom="20px">
// 						{post.title}
// 					</Heading>
// 					<Box height="100px">
// 						<CodeEditorView
// 							readOnly={true}
// 							interactionDisabled
// 							value={post.codeText}
// 							languageMode={post.languageMode}
// 							onCursorPositionChange={(value) => {}}
// 							onTextChange={() => {}}
// 							name={`${post.snippetId}-div`}
// 							width="100%"
// 							height="100%"
// 						/>
// 						{/* <Box position="relative" top="-100px" height="100px"></Box> */}
// 					</Box>

// 					{/* </Box> */}
// 					<Flex direction="row" justifyContent="center" marginTop="10px">
// 						<Text fontSize="xs" fontStyle="italic">
// 							{`${post.dateTime.toDateString()} (${post.dateTime.getHours()}:${post.dateTime.getMinutes()})`}
// 						</Text>
// 						<Spacer />
// 						<Text fontSize="xs">revision {post.revision}</Text>
// 					</Flex>
// 				</Box>
// 			</LinkOverlay>
// 		</LinkBox>
// 	);
// };
