import React, { useContext, useEffect, useState } from "react";
import { SignInStatus } from "../../services/backend/api-service-interface";
import {
	AuthUserCredential,
	BasicAuthService,
} from "../../services/backend/auth-service";
import { BasicBackendSignInService } from "../../services/backend/signin-service";
import { LogInfo } from "../../util/util";

const DefaultUserCredential: AuthUserCredential = { user: null };
const DefaultSignInMethod = (email: string, password: string) => {
	return Promise.resolve({ status: false, message: "" });
};
const DefaultSignOutMethod = () => {
	return Promise.resolve(true);
};
const DefaultContextValue: AuthContextProps = {
	userCredential: DefaultUserCredential,
	signUserIn: DefaultSignInMethod,
	signUserOut: DefaultSignOutMethod,
	isAuthenticated: () => false,
};

const AUTH_STATUS = {
	UserIsAuthenticated: "is-authenticated",
	UserNotAuthenticated: "not-authenticated",
};

const LOCAL_STORAGE_AUTH_KEY = "IsLoggenIn";

/**
 * SetIsAuthInLocalStorage.
 * Why use local storage? This allows smooth rerendering
 * of the pages.
 * @param value
 */
const SetIsAuthInLocalStorage = (value: string) => {
	localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, value);
};

interface AuthContextProps {
	userCredential: AuthUserCredential;
	signUserIn: (email: string, password: string) => Promise<SignInStatus>;
	isAuthenticated: () => boolean;
	signUserOut: () => Promise<boolean>;
}

const AuthContext = React.createContext(DefaultContextValue);

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
	const [currentUserCredential, setCurrentUserCredential] = useState(
		DefaultUserCredential
	);

	const signUserIn = async (
		email: string,
		password: string
	): Promise<SignInStatus> => {
		return new Promise<SignInStatus>((resolve, reject) => {
			new BasicBackendSignInService()
				.signUserIn(email, password)
				.then((res) => {
					if (res.status) {
						SetIsAuthInLocalStorage(AUTH_STATUS.UserIsAuthenticated);
					} else {
						SetIsAuthInLocalStorage(AUTH_STATUS.UserNotAuthenticated);
					}
					resolve(res);
				})
				.catch((err) => {
					SetIsAuthInLocalStorage(AUTH_STATUS.UserNotAuthenticated);
					reject(err);
				});
		});
	};

	const signUserOut = () => {
		return new BasicBackendSignInService().signOut();
	};

	const isAuthenticated = (): boolean => {
		// Read from local storage
		const res =
			localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) ===
			AUTH_STATUS.UserIsAuthenticated;
		LogInfo("AuthRES ", res);
		return res;
		// return currentUserCredential.user !== null;
	};

	useEffect(() => {
		const unsubscribe = new BasicAuthService().onAuthStateChanged((user) => {
			if (user.user) {
				SetIsAuthInLocalStorage(AUTH_STATUS.UserIsAuthenticated);
			} else {
				SetIsAuthInLocalStorage(AUTH_STATUS.UserNotAuthenticated);
			}
			setCurrentUserCredential(user);
		});
		return unsubscribe;
	}, []);

	const value: AuthContextProps = {
		userCredential: currentUserCredential,
		signUserIn,
		isAuthenticated,
		signUserOut,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
