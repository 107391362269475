import { LanguageMode } from "./language-modes";

export interface PendingCodeReviewComment {
	id: string;
	lineStart: number;
	lineStop: number;
	reviewText: string;
}

export interface CodeReviewComment {
	commentId: string;
	// author of comment
	authorName: string;
	// comment info
	lineStart: number;
	lineStop: number;
	reviewText: string;
	// Date and time
	dateTime: Date; // DD-MM-YY hh:mm AM|PM
}

export interface CodeReviewComments {
	comments: CodeReviewComment;
	snippetId: string;
}

export interface CodeSnippet {
	isAuthor: boolean;
	hasBeenReviewed: boolean;
	snippetId: string;
	authorName: string;
	title: string;
	languageMode: string;
	codeText: string;
	// Revision
	revision: number; // revision number
	latestRevision: number; // latest revision
	dateTime: Date; // DD-MM-YY hh:mm AM|PM
	//
	reviewId?: string;
}

export interface CodeSnippetForReview extends CodeSnippet {}

export const CRNotificationType = {
	codereviewComment: "codereviewComment",
	codereviewRequest: "codereviewRequest",
};

export interface CRNotification {
	notificationType: string;
	notificationId: string;
	activityCreatedDateTime: Date;
}

export interface CRCommentNotification extends CRNotification {
	snippetId: string; // CodeReviewPost that this notification is tied to
	revision: number;
	snippetTitle: string; // title of the notification
	description: string;
	dateTime: Date;
}

export interface CRRequestNotification extends CRNotification {
	reviewId: string; // reviewRequestId
	snippetId: string; // CodeReviewPost that this notification is tied to
	revision: number;
	snippetTitle: string; // title of the notification
	description: string;
	dateTime: Date;
}

export const CreateDefaultPendingComment = (): PendingCodeReviewComment => {
	return {
		lineStop: 1,
		lineStart: 1,
		reviewText: "",
		id: "",
	};
};
export const CreateDefaultPost = (): CodeSnippet => {
	return {
		isAuthor: false,
		hasBeenReviewed: false,
		snippetId: "",
		authorName: "",
		title: "",
		codeText: "",
		languageMode: LanguageMode.language.python,
		dateTime: new Date(),
		revision: 0,
		latestRevision: 0,
	};
};
