import { Box, Flex, Button, VStack, Text } from "@chakra-ui/react";
import React from "react";
import {
	CodeReviewComment,
	PendingCodeReviewComment,
} from "../../models/models";
import { CodeReviewCommentEditView } from "./code-review-comment-edit-view";
import { CodeReviewCommentView } from "./code-review-comment-view";

interface CodeReviewCommentsViewProps {
	isPostingReviews: boolean;
	activeReviewCommentId: string;
	reviews: CodeReviewComment[];
	pendingReviews: PendingCodeReviewComment[];
	onSendPendingReviews: () => void;
	onCodeReviewCommentSelected: (comment: CodeReviewComment) => void;
	onPendingCodeReviewCommentSelected: (
		comment: PendingCodeReviewComment
	) => void;
	onUpdatePendingReviewComment: (comment: PendingCodeReviewComment) => void;
	onRemovePendingReviewComment: (commentId: string) => void;
}
export const CodeReviewCommentsView = (props: CodeReviewCommentsViewProps) => {
	const { reviews, pendingReviews, activeReviewCommentId } = props;
	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			flexDirection="column"
			alignItems="stretch"
			paddingLeft="20px"
			paddingRight="20px"
			paddingBottom="10px"
			// borderWidth="1px"
			// background="orange.100"
		>
			<Flex
				direction="row"
				textAlign="center"
				justifyContent="center"
				alignItems="center"
				marginBottom="10px"
				marginTop="20px"
			>
				<Text>Reviews ({reviews.length + pendingReviews.length})</Text>
				{props.isPostingReviews ? (
					<Button
						colorScheme="blue"
						size="sm"
						marginLeft="10px"
						onClick={() => props.onSendPendingReviews()}
						isLoading={props.isPostingReviews}
						loadingText="Send"
					>
						Send
					</Button>
				) : (
					<Button
						disabled={pendingReviews.length <= 0}
						colorScheme="blue"
						size="sm"
						marginLeft="10px"
						onClick={() => props.onSendPendingReviews()}
					>
						Send
					</Button>
				)}
			</Flex>
			<Box width="100%" height="100%" overflowY="auto">
				<VStack width="100%" height="100%" alignItems="stretch">
					{reviews.map((review) => (
						<CodeReviewCommentView
							isActive={review.commentId === activeReviewCommentId}
							key={review.commentId}
							comment={review}
							onSelect={() => {
								props.onCodeReviewCommentSelected(review);
							}}
						/>
					))}

					{pendingReviews.map((review) => (
						<CodeReviewCommentEditView
							isActive={review.id === activeReviewCommentId}
							key={review.id}
							comment={review}
							onSelect={() => {
								props.onPendingCodeReviewCommentSelected(review);
								// handlePendingCodeReviewCommentSelected(review);
							}}
							onUpdate={(value) => {
								props.onUpdatePendingReviewComment(value);
							}}
							onRemove={() => {
								props.onRemovePendingReviewComment(review.id);
							}}
						/>
					))}
				</VStack>
			</Box>
		</Box>
	);
};
