import { LogInfo } from "../../util/util";
import { BackendSignInApiService, SignInStatus } from "./api-service-interface";
import { FirebaseServiceApp } from "./firebase-service";

export class BasicBackendSignInService implements BackendSignInApiService {
	async signUserIn(email: string, password: string) {
		return new Promise<SignInStatus>(async (resolve, reject) => {
			await FirebaseServiceApp.auth()
				.signInWithEmailAndPassword(email, password)
				.then(async (res) => {
					// Check User claim
					const idTokenResult = await res.user?.getIdTokenResult();
					if (idTokenResult && idTokenResult.claims.role === "reviewer") {
						const status: SignInStatus = {
							status: true,
							message: "sign in successful",
						};
						LogInfo("User status", status);
						resolve(status);
					} else {
						throw new Error("Invalid Credential");
					}
				})
				.catch((err) => {
					LogInfo("Error", err);
					const emalErrorStatus: SignInStatus = {
						status: false,
						message:
							"Either email or password is invalid or you dont have permission to view",
					};
					reject(emalErrorStatus);
				});
		});
	}

	signOut() {
		return new Promise<boolean>((resolve, reject) => {
			FirebaseServiceApp.auth()
				.signOut()
				.then((res) => {
					resolve(true);
				})
				.catch((err) => {
					reject(false);
				});
		});
	}
}
