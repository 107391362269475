import {
	Box,
	Flex,
	Heading,
	HStack,
	Select,
	SimpleGrid,
	Spacer,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PageLayout } from "../view-components/components/page-layout";
import { CodeSnippetForReview } from "../models/models";
import { RoutePaths } from "../services/route/route_paths";
import { LogError } from "../util/util";
import { useAuth } from "../view-components/components/auth-context";
import { SpinnerBackdrop } from "../view-components/components/spinner-backdrop";
import { LogInfo } from "../util/util";
import {
	FilterSnippetListService,
	SortSnippetListService,
} from "../services/lib/sort-filter-snippets";
import { CodeSnippetMiniView } from "../view-components/components/code-snippet-mini-view";
import { BasicReviewerCodeReviewApiService } from "../services/backend/reviewer-codereview-api-service";

interface SnippetsForReviewViewProps {
	showOnlyReviewedSnippet: boolean;
	listTitle: string;
	locName?: string;
}

const SnippetsForReviewView = (props: SnippetsForReviewViewProps) => {
	const defaultPosts: CodeSnippetForReview[] = [];
	const [docStateOriginalPosts, setDocStateOriginalPosts] = useState(
		defaultPosts
	);
	const [posts, setPosts] = useState(defaultPosts);
	const [isFetchingPosts, setIsFetchingPosts] = useState(true);
	// const defaultFilterSortTransform: FilterAndSortTransform = {filterBy: "all", sortBy: "date"};
	const [uiStateFilterBy, setUiStateFilterBy] = useState("all");
	const [uiStateSortBy, setUiStateSortBy] = useState("date");

	const { userCredential } = useAuth();

	useEffect(() => {
		setIsFetchingPosts(true);
		new BasicReviewerCodeReviewApiService(userCredential)
			.getSnippetsForReview(props.showOnlyReviewedSnippet)
			.then((res) => {
				setPosts(res);
				setDocStateOriginalPosts(res);
				setIsFetchingPosts(false);
			})
			.catch((err) => {
				LogError("error fetching posts");
				setPosts([]);
				setIsFetchingPosts(false);
			});
	}, [userCredential, props.showOnlyReviewedSnippet]);
	const postsGridView = (
		<SimpleGrid
			columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
			spacing={["15px", "40px"]}
			paddingBottom={["10px"]}
		>
			{posts.map((post) => (
				<CodeSnippetMiniView
					key={post.reviewId}
					post={post}
					detaiLink={RoutePaths.codeReviewDetailRoute(
						post.snippetId,
						post.revision,
						post.reviewId || ""
					)}
				/>
			))}
		</SimpleGrid>
	);

	const handleFilterAndSortSnippets = (filterBy: string, sortBy: string) => {
		LogInfo("Filtering By", filterBy);
		const filterdList = new FilterSnippetListService().filterBy(
			docStateOriginalPosts,
			filterBy
		);
		// sort
		const sortedFilteredList = new SortSnippetListService().sortBy(
			filterdList,
			sortBy
		);
		setPosts(sortedFilteredList);
		setUiStateFilterBy(filterBy);
		setUiStateSortBy(sortBy);
	};

	return (
		<PageLayout locName={props.locName}>
			<Box
				display="flex"
				flexDirection="column"
				padding={["20px", "40px"]}
				height="100%"
				// background="orange"
				overflowY="auto"
				// marginBottom="20px"
			>
				{/* Top Margin */}
				<Flex
					marginTop="1em"
					direction="column"
					height="100%"

					// background="green"
				>
					<SimpleGrid
						columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
						spacing={["15px", "40px"]}
						paddingBottom={["10px"]}
						marginBottom="1.2em"
					>
						<Heading fontSize={["1xl", "3xl"]}>
							{props.listTitle}({posts.length})
						</Heading>
						{/* <Spacer /> */}

						<HStack width="100%">
							<Spacer />
							<Select
								maxWidth="200px"
								placeholder="Sort by"
								value={uiStateSortBy}
								onChange={(e) =>
									handleFilterAndSortSnippets(uiStateFilterBy, e.target.value)
								}
							>
								<option value={SortSnippetListService.byDate}>
									SortBy: date
								</option>
								<option value={SortSnippetListService.byName}>
									SortBy: name
								</option>
							</Select>
							{/* <Select
								maxWidth="200px"
								placeholder="Filter by"
								// defaultValue={FilterSnippetListService.all}
								value={uiStateFilterBy}
								onChange={(e) => {
									console.log(e);
									handleFilterAndSortSnippets(e.target.value, uiStateSortBy);
								}}
							>
								<option value={FilterSnippetListService.all}>
									Filter: All
								</option>
								<option value={FilterSnippetListService.reviewed}>
									Filter: Reviewed
								</option>
								<option value={FilterSnippetListService.pending}>
									Filter: Pending
								</option>
							</Select> */}
						</HStack>
					</SimpleGrid>

					{isFetchingPosts ? (
						<Flex
							direction="column"
							height="80%"
							justifyContent="flex-start"
							alignItems="center"
						>
							<SpinnerBackdrop shortDescription="Fetching snippets" />
						</Flex>
					) : (
						postsGridView
					)}
				</Flex>
			</Box>
		</PageLayout>
	);
};

export const SnippetsPendingReviewView = () => {
	return (
		<SnippetsForReviewView
			listTitle="Pending Reviews"
			showOnlyReviewedSnippet={false}
			locName="pending-reviews"
		/>
	);
};
export const SnippetsReviewedView = () => {
	return (
		<SnippetsForReviewView
			listTitle="Reviewed"
			locName="reviewed"
			showOnlyReviewedSnippet={true}
		/>
	);
};
