export class RoutePaths {
	static createCodeSnippetRoute = () => {
		return "/snippets/new";
	};

	static codeSnippetDetailRoute = (snippetId: string) => {
		return `/${snippetId}`;
	};
	static codeSnippetDetailWithRevisionRoute = (
		snippetId: string,
		revision: number
	) => {
		return `/${snippetId}/${revision}`;
	};

	static codeReviewDetailRoute = (
		snippetId: string,
		revision: number,
		reviewId: string
	) => {
		return `/codereview/${snippetId}/${revision}/${reviewId}`;
	};

	static codeSnippetListRoute = () => {
		return `/snippets`;
	};
	static pendingReviewsListRoute = () => {
		return `/pending-reviews`;
	};
	static reviewedSnippetsListRoute = () => {
		return `/reviewed-snippets`;
	};
	static loginRoute = () => {
		return `/login`;
	};
	static landing = () => {
		return `/`;
	};
}

export class AppRoutePathsFormat {
	static createCodeSnippetRoute = () => {
		return "/snippets/new";
	};

	static codeSnippetDetailRoute = (snippetId: string) => {
		return `/${snippetId}`;
	};
	static codeSnippetDetailWithRevisionRoute = (
		snippetId: string,
		revision: string
	) => {
		return `/${snippetId}/${revision}`;
	};

	static codeReviewDetailRoute = (
		snippetId: string,
		revision: string,
		reviewId: string
	) => {
		return `/codereview/${snippetId}/${revision}/${reviewId}`;
	};

	static pendingReviewsListRoute = () => {
		return `/pending-reviews`;
	};
	static reviewedSnippetsListRoute = () => {
		return `/reviewed-snippets`;
	};

	static codeSnippetListRoute = () => {
		return `/snippets`;
	};
	static loginRoute = () => {
		return `/login`;
	};
	static landing = () => {
		return `/`;
	};
}
