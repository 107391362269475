import * as React from "react";
// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from "react-router-dom";
import { HomeView } from "./pages/home";
import { CodeSnippetDetailView } from "./pages/code-snippet-detail-view";
import { CreateCodeSnippetView } from "./pages/create-code-snippet-view";
import { AppRoutePathsFormat } from "./services/route/route_paths";
import { LoginView } from "./pages/login-view";
import {
	AuthProvider,
	useAuth,
} from "./view-components/components/auth-context";
import {
	SnippetsPendingReviewView,
	SnippetsReviewedView,
} from "./pages/snippets-for-review";
import { CodeReviewDetailView } from "./pages/code-review-detail-view";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
	const { isAuthenticated } = useAuth();

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated() ? ( //
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: AppRoutePathsFormat.loginRoute(),
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

// TODO: User should not be able to post Empty Snippet. Should have at least
//    a title and one line of code.
// TODO: When user Makes a post, show a spinner to indicate Sending
//    and disable the Request button.
// TODO: When fetching posts, show spinner to indicate progress.
// TODO: User should be able to reply a review comment.
// TODO: Reviewer should have a way of Viewing Notes on any revision.
// TODO: User should be able to see previous revisions.
//      Note: Creation of a new review should have place comments.
// TODO: Reviewer should be able to see notes attached to the review.
// TODO:
const App = () => {
	// Add Firebase authentication (only show other pages upon authentication)
	// Create Firebase database for users
	// Add Firebase Cloud http functions for posting and getting
	// Add Firebase Pub Sub to publish notifcatiions when Review is posted

	// ssetup auth listener

	return (
		<ChakraProvider>
			<AuthProvider>
				<Router>
					<Switch>
						<PrivateRoute
							component={CreateCodeSnippetView}
							path={AppRoutePathsFormat.createCodeSnippetRoute()}
							exact
						/>

						<PrivateRoute
							component={HomeView}
							path={AppRoutePathsFormat.codeSnippetListRoute()}
							exact
						/>

						<PrivateRoute
							component={SnippetsPendingReviewView}
							path={AppRoutePathsFormat.pendingReviewsListRoute()}
							exact
						/>
						<PrivateRoute
							component={SnippetsReviewedView}
							path={AppRoutePathsFormat.reviewedSnippetsListRoute()}
							exact
						/>

						<Route path={AppRoutePathsFormat.loginRoute()} exact>
							<LoginView />
						</Route>

						<PrivateRoute
							component={CodeReviewDetailView}
							path={AppRoutePathsFormat.codeReviewDetailRoute(
								":snippetId",
								":revision",
								":reviewId"
							)}
							exact
						/>

						<PrivateRoute
							component={CodeSnippetDetailView}
							path={AppRoutePathsFormat.codeSnippetDetailWithRevisionRoute(
								":snippetId",
								":revision"
							)}
							exact
						/>

						<PrivateRoute
							component={CodeSnippetDetailView}
							path={AppRoutePathsFormat.codeSnippetDetailRoute(":snippetId")}
							exact
						/>

						<PrivateRoute component={HomeView} path="/" exact />
					</Switch>
				</Router>
			</AuthProvider>
		</ChakraProvider>
	);
};

export default App;
