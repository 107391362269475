import { FirebaseServiceApp } from "./firebase-service";
import firebase from "firebase/app";
import { LogInfo } from "../../util/util";

export interface AuthUserCredential {
	user: firebase.User | null;
}

export interface OnAuthStateChanged {
	(user: AuthUserCredential): void;
}

export interface AuthUnsubscribe {
	(): void;
}

export interface AuthService {
	onAuthStateChanged(callback: OnAuthStateChanged): AuthUnsubscribe;
}

export class BasicAuthService implements AuthService {
	onAuthStateChanged(callback: OnAuthStateChanged) {
		const unsubscribe = FirebaseServiceApp.auth().onAuthStateChanged(
			async (user) => {
				// Verify The User has the right roles access.
				const idTokenResult = await user?.getIdTokenResult();
				if (idTokenResult && idTokenResult.claims.role === "reviewer") {
					LogInfo("Call auth change: User role is not valid. ");
					const userCredential: AuthUserCredential = { user: user };
					callback(userCredential);
				} else {
					LogInfo("Call auth change: User Unauthenticated");
					const userCredential: AuthUserCredential = { user: null };
					callback(userCredential);
				}
			}
		);
		const unsubscribeWrapper = () => {
			unsubscribe();
		};
		return unsubscribeWrapper;
	}
}
