import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Img,
	Input,
	Link,
	VStack,
	Text,
	Button,
	FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { RoutePaths } from "../services/route/route_paths";
import logo from "../asset/logo.png";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { LogInfo } from "../util/util";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../view-components/components/auth-context";

interface LoginViewProps {
	[name: string]: any;
}

interface LoginValues {
	email: string;
	password: string;
}

const ValidateEmail = (email: string) => {
	let message;
	if (email.length === 0) {
		message = "Email is required";
	}
	return message;
};
const ValidatePassword = (password: string) => {
	let message;
	if (password.length === 0) {
		message = "Password is required";
	}
	return message;
};

export const LoginView = (props: LoginViewProps) => {
	const [errorMessage, setErrorMessage] = useState("");
	const history = useHistory();
	const { signUserIn } = useAuth();
	const location = useLocation();

	let { from }: any = location.state || {
		from: { pathname: RoutePaths.landing() },
	};
	return (
		<Flex
			height="100vh"
			// background="orange"
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box
				// background="orange.100"
				margin="auto"
				width="20%"
				borderWidth="1px"
				padding="40px"
				display="flex"
				minWidth="350px"
				maxWidth="400px"
				// boxSize="md"
				flexDirection="column"
				justifyContent="center"
				// alignItems="stretch"
				rounded="lg"
			>
				<VStack marginBottom="20px">
					<Link to={RoutePaths.codeSnippetListRoute()}>
						<Img src={logo} width="32px" height="32px" alt="ilearnswe logo" />
					</Link>
					<Text fontSize="sm">ilearnswe</Text>
				</VStack>
				{errorMessage.length > 0 ? (
					<Box textAlign="center" marginBottom="20px">
						<Text fontSize="xs" color="red">
							{errorMessage}
						</Text>
					</Box>
				) : null}
				{/* Email Field */}
				<Formik
					initialValues={{
						email: "",
						password: "",
					}}
					onSubmit={(
						values: LoginValues,
						{ setSubmitting }: FormikHelpers<LoginValues>
					) => {
						LogInfo("calling");
						setSubmitting(true);
						setErrorMessage("");

						signUserIn(values.email, values.password)
							.then((res) => {
								// history.push(RoutePaths.codeSnippetListRoute());
								history.replace(from);
							})
							.catch((err) => {
								LogInfo("Error Occured while trying to signin");
								setErrorMessage("Either Email or password is incorrect");
								setSubmitting(false);
							});
					}}
				>
					{(props) => (
						<Form>
							<Field name="email" id="email" validate={ValidateEmail}>
								{({ field, form }: any) => (
									<FormControl
										id="email"
										isInvalid={form.errors.email && form.touched.email}
									>
										<FormLabel htmlFor="email">Email address</FormLabel>
										<Input
											{...field}
											type="email"
											id="email"
											placeholder="Email"
											size="md"
										/>
										<FormErrorMessage>{form.errors.email}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							<Field name="password" id="password" validate={ValidatePassword}>
								{({ field, form }: any) => (
									<FormControl
										id="password"
										// size="lg"
										isInvalid={form.errors.password && form.touched.password}
										marginTop={4}
									>
										<FormLabel htmlFor="password">Password</FormLabel>
										<Input
											{...field}
											type="password"
											id="password"
											placeholder="Password"
										/>
										<FormErrorMessage>{form.errors.password}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							{/* Password Field  */}
							{/* <FormControl id="password">
								<FormLabel>Password</FormLabel>
								<Input type="password" />
							</FormControl> */}

							<Button
								colorScheme="blue"
								mt={6}
								isLoading={props.isSubmitting}
								type="submit"
							>
								Sign in
							</Button>
						</Form>
					)}
				</Formik>
			</Box>
		</Flex>
	);
};
