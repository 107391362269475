import { CodeSnippet, CodeSnippetForReview } from "../../models/models";
import { LogInfo } from "../../util/util";
import { AuthUserCredential } from "./auth-service";
import { FirebaseFunctionsService } from "./firebase-service";

interface CMCodeSnippetForReview {
	reviewId: string;
	isAuthor: boolean;
	hasBeenReviewed: boolean; // if snippet has been reviewed or not.
	snippetId: string;
	authorName: string;
	title: string;
	languageMode: string;
	codeText: string;
	// Revision
	revision: number; // revision number
	latestRevision: number; // latest revision
	timestampMilli: number; // time stamp in milliseconds

	isReview?: boolean;
}

interface MarkAsReviewedRequest {
	reviewId: string;
}

interface MarkAsReviewedResponse {
	status?: boolean;
}

/**
 *
 */
interface GetSnippetsForReviewRequest {
	whereHasBeenReviewed: boolean;
}

interface GetSnippetsForReviewResponse {
	snippets: CMCodeSnippetForReview[];
}

interface GetSnippetDetailForReviewRequest {
	reviewId: string;
}

interface GetSnippetDetailForReviewResponse {
	snippet: CMCodeSnippetForReview;
}

export class BasicReviewerCodeReviewApiService {
	// user credential
	userCredential: AuthUserCredential;

	constructor(userCredential: AuthUserCredential) {
		this.userCredential = userCredential;
	}
	// TODO: Remove this until you need it.
	getSnippetDetailForReview(
		request: GetSnippetDetailForReviewRequest
	): Promise<CodeSnippet> {
		return new Promise((resolve, reject) => {
			const GetSnippetDetailForReviewCallable = FirebaseFunctionsService.httpsCallable(
				"Reviewer_SnippetService_GetSnippetDetailForReview"
			);
			LogInfo("Sending GetSnippetDetailForReview request to server");
			GetSnippetDetailForReviewCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetSnippetDetailForReviewResponse;
					// TODO: response might not have the required snippet. Pls fix.
					if (!response.snippet) {
						reject("Snippet not available");
						return;
					}
					const responseData = response.snippet;
					const snippet: CodeSnippet = {
						reviewId: responseData.reviewId,
						isAuthor: responseData.isAuthor,
						hasBeenReviewed: responseData.hasBeenReviewed,
						snippetId: responseData.snippetId,
						authorName: responseData.authorName,
						title: responseData.title,
						languageMode: responseData.languageMode,
						codeText: responseData.codeText,
						// Revision
						revision: responseData.revision, // revision number
						latestRevision: responseData.latestRevision,
						dateTime: new Date(responseData.timestampMilli),
					};

					LogInfo("retrieved snippet", responseData);
					resolve(snippet);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getSnippetsForReview(
		whereHasBeenReviewed: boolean
	): Promise<CodeSnippetForReview[]> {
		const request: GetSnippetsForReviewRequest = {
			whereHasBeenReviewed: whereHasBeenReviewed,
		};
		return new Promise((resolve, reject) => {
			const GetSnippetsForReviewCallable = FirebaseFunctionsService.httpsCallable(
				"Reviewer_SnippetService_GetSnippetsForReview"
			);
			LogInfo("Sending GetSnippetsForReview request to server");
			GetSnippetsForReviewCallable(request)
				.then((result) => {
					// Read result of cloud function.
					const response = result.data as GetSnippetsForReviewResponse;
					const responseData = response.snippets;
					const snippetList: CodeSnippetForReview[] = [];
					responseData.forEach((cmSnippet) => {
						snippetList.push({
							reviewId: cmSnippet.reviewId,
							isAuthor: cmSnippet.isAuthor,
							hasBeenReviewed: cmSnippet.hasBeenReviewed,
							snippetId: cmSnippet.snippetId,
							authorName: cmSnippet.authorName,
							title: cmSnippet.title,
							languageMode: cmSnippet.languageMode,
							codeText: cmSnippet.codeText,
							// Revision
							revision: cmSnippet.revision, // revision number
							latestRevision: cmSnippet.latestRevision,
							dateTime: new Date(cmSnippet.timestampMilli),
						});
					});
					LogInfo("retrieved snippet list", snippetList);
					resolve(snippetList);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	markAsReviewed(reviewId: string) {
		return new Promise((resolve, reject) => {
			const request: MarkAsReviewedRequest = { reviewId: reviewId };
			const MarkAsReviewedCallable = FirebaseFunctionsService.httpsCallable(
				"Reviewer_SnippetService_MarkAsReviewed"
			);
			LogInfo("Sending MarkAsReviewed request to server");
			MarkAsReviewedCallable(request)
				.then((result) => {
					LogInfo("Received from server ", result);
					// Read result of cloud function.
					const response = result.data as MarkAsReviewedResponse;
					// TODO: response might not have the required snippet. Pls fix.
					if (!response.status) {
						throw new Error("Failed to mark as reviewed");
					}
					resolve(response.status);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
